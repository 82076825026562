@use '@angular/material' as mat;
//@import "@angular/material/theming";
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
//@include mat.typography-hierarchy(mat.define-typography-config());
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
//@include mat.all-legacy-component-typographies();
//@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
//@include mat.legacy-core();
@include mat.core();

$md-primary: (
    50 : #fdf0ea,
    100 : #fbdaca,
    200 : #f8c1a7,
    300 : #f5a883,
    400 : #f29569,
    500 : #f0824e,
    600 : #ee7a47,
    700 : #ec6f3d,
    800 : #e96535,
    900 : #e55225,
    A100 : #ffffff,
    A200 : #fff0ec,
    A400 : #ffc8b9,
    A700 : #ffb4a0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-accent: (
    50 : #eaf8fd,
    100 : #caedfb,
    200 : #a7e1f8,
    300 : #83d4f5,
    400 : #69cbf2,
    500 : #4ec2f0,
    600 : #47bcee,
    700 : #3db4ec,
    800 : #35ace9,
    900 : #259fe5,
    A100 : #ffffff,
    A200 : #ecf8ff,
    A400 : #b9e4ff,
    A700 : #a0daff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$custom-collection: (
    warning: #ffc116,
    success: #5ac18e,
    danger: #ff6666,
    gray: #e0e0e0,
    contrast: (
        warning: #fff,
        success: #fff,
        danger: #fff,
        gray: rgba(0, 0, 0, 0.26)
    )
);

//$my-app-custom: mat.define-palette($custom-collection, custom);
$my-app-primary: mat.m2-define-palette($md-primary, 500, 300, 700);
$my-app-accent: mat.m2-define-palette($md-accent, 700, 500, 800);
$my-app-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);


$custom-typography: mat.m2-define-typography-config(
  $headline-1: mat.m2-define-typography-level(16px, 24px, 500, $letter-spacing: -0.05em),
  $body-1: mat.m2-define-typography-level(12px, 16px, 400),
  $body-2: mat.m2-define-typography-level(12px, 16px, 400),
  $subtitle-2: mat.m2-define-typography-level(12px, 16px, 600),
  $button: mat.m2-define-typography-level(12px, 16px, 400), 
  $caption: mat.m2-define-typography-level(12px, 16px, 300),
);

$custom-typography2: mat.m2-define-typography-config(
  $headline-1: mat.m2-define-typography-level(16px, 24px, 500, $letter-spacing: -0.05em),
  $body-1: mat.m2-define-typography-level(12px, 16px, 400),
  $body-2: mat.m2-define-typography-level(12px, 16px, 400),
  $subtitle-2: mat.m2-define-typography-level(12px, 16px, 600),
  $button: mat.m2-define-typography-level(14px, 20px, 400), 
  $caption: mat.m2-define-typography-level(12px, 16px, 300),
);

$my-app-theme: mat.m2-define-light-theme((
    color:(primary: $my-app-primary, accent:$my-app-accent, warn:$my-app-warn),
//    typography: mat.define-typography-config(),
    typography: $custom-typography,
    density: 0
));


//@include mat.form-field-density((,0.5));
//$my-app-theme: map_merge($my-app-theme, (custom: $my-app-custom));

//$custom: map-get($my-app-theme, custom);

/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
//@include mat.all-legacy-component-themes($my-app-theme);


@include mat.all-component-themes($my-app-theme);
//@include mat.button-typography($custom-typography2);

@include mat.checkbox-density(-5);
//@include mat.autocomplete-density(-5);

.mat-primary {
    &.mat-button-base,
    &.mat-mdc-button-base:not(:disabled) {
        //background-color: mat.get-color-from-palette($custom, success);
        background-color: #f0824e;
        //color: mat.get-color-from-palette($custom, success-contrast);
        color: #fff;
    }

    &.mat-progress-spinner circle, &.mat-spinner circle {
        //stroke: mat.get-color-from-palette($custom, success);
        stroke: #f0824e;
    }
}

.mat-blank {
    &.mat-button-base,
    &.mat-mdc-button-base:not(:disabled) {
        //background-color: mat.get-color-from-palette($custom, success);
        background-color: #fff;
        //color: mat.get-color-from-palette($custom, success-contrast);
        color: #333;
        border:solid 1px #333
    }

    &.mat-progress-spinner circle, &.mat-spinner circle {
        //stroke: mat.get-color-from-palette($custom, success);
        stroke: #333;
    }
}

.mat-success {
    &.mat-button-base,
    &.mat-mdc-button-base:not(:disabled) {
        //background-color: mat.get-color-from-palette($custom, success);
        background-color: #5ac18e;
        //color: mat.get-color-from-palette($custom, success-contrast);
        color: #fff;
    }

    &.mat-progress-spinner circle, &.mat-spinner circle {
        //stroke: mat.get-color-from-palette($custom, success);
        stroke: #5ac18e;
    }
}

.mat-warning {
    &.mat-button-base,
    &.mat-mdc-button-base:not(:disabled) {
        //background-color: mat.get-color-from-palette($custom, warning);
        background-color: #ffc116;
        //color: mat.get-color-from-palette($custom, warning-contrast);
        color: #fff;
    }

    &.mat-progress-spinner circle, &.mat-spinner circle {
        //stroke: mat.get-color-from-palette($custom, warning);
        stroke: #ffc116;
    }
}

.mat-danger {
    &.mat-button-base,
    &.mat-mdc-button-base:not(:disabled) {
        //background-color: mat.get-color-from-palette($custom, danger);
        background-color: #ff6666;
        //color: mat.get-color-from-palette($custom, danger-contrast);
        color: #fff;
    }

    &.mat-progress-spinner circle, &.mat-spinner circle {
        //stroke: mat.get-color-from-palette($custom, danger);
        stroke: #ff6666;
    }
}

.mat-gray {
    &.mat-button-base,
    &.mat-mdc-button-base:not(:disabled) {
        //background-color: mat.get-color-from-palette($custom, gray);
        background-color: #e0e0e0;
        //color: mat.get-color-from-palette($custom, gray-contrast);
        color: rgba(0, 0, 0, 0.26)
    }

    &.mat-progress-spinner circle, &.mat-spinner circle {
        //stroke: mat.get-color-from-palette($custom, gray);
        stroke: #e0e0e0,
    }
}

.mat-white {
    .mat-checkbox-background:not(:disabled), .mat-checkbox-frame {
        border-color: #ffffff !important;
    }

    &.mat-checkbox-checked, &.mat-checkbox-indeterminate {
        .mat-checkbox-checkmark-path {
            stroke: #4299e1 !important;
        }

        .mat-checkbox-mixedmark {
            background-color: #4299e1 !important;
        }

        .mat-checkbox-background {
            background: #ffffff !important;
        }
    }
}