html, body
    background-color: #fafafa
    font-family: 'Roboto', sans-serif
    min-height: 100vh

.mat-drawer
    width: 200px

select:-moz-focusring
    color: transparent
    text-shadow: 0 0 0 #000

hr
    margin-top: 1rem
    margin-bottom: 1rem
    border: 0
    border-top: 1px solid rgba(#000000, .1)

.pos-center
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.flex-equal-w
    flex-grow: 1
    flex-basis: 0

.age-restriction
    color: #fff
    font-weight: bold
    font-size: 0.6rem
    border-radius: 50%
    background: #68a49d
    border: 1px solid #5b9790
    display: flex
    align-items: center
    justify-content: center
    height: 1.8rem
    width: 1.8rem

mat-form-field[appearance=outlined]
    .mat-form-field-suffix:not(.default)
        top: 0.6rem !important